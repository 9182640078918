<template>
  <div>
    <b-card>
      <b-row>
        <b-col cols="2">
          <b-button variant="success" :disabled="isSimulationRunning" @click="startSimulation">
            {{ $t("Start Simulation") }} </b-button>
        </b-col>
        <b-col lg="4" class="my-1">
          <b-form-group label-cols-sm="4" class="w-75" label-align-sm="right" label-size="m"
            :label="$i18n.t('Value Type')">
            <v-select id="mouldId" v-model="selectedDropdown" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="code" :options="dropdownOptions">
            </v-select>
          </b-form-group>
        </b-col>
        <!--
        <b-col lg="6" class="my-1">
          <div class="d-flex align-items-center justify-content-end">
            <b-form-group
              label-cols-sm="3"
              class="mb-0 w-75"
              label-align-sm="right"
              label-size="sm"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filter-input"
                  v-model="filter"
                  style="heigth: 2.5rem"
                  type="search"
                  :placeholder="$t('Type Search Group')"
                >
                </b-form-input>

                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''"
                    > {{ $t('Clear')}}</b-button
                  >
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
       
        </b-col>
           -->
      </b-row>
      <b-row>
        <b-col>
          <b-table hover selectable select-mode="single" class="click-table" stickyColumn responsive
            :filter="dropdownMap.get(selectedDropdown)" @filtered="onFiltered" :items="tableData" :fields="tableColumns"
            show-empty no-local-sorting :per-page="perPage" empty-text="No matching records found"
            :currentPage="currentPage">
            <template #cell(startTime)="data">
              {{ format_date(data.item.startTime) }}
            </template>
            <template #cell(endTime)="data">
              {{ format_date(data.item.endTime) }}
            </template>
            <template #head(action)="data">
              <div class="">{{ $t(data.label) }}</div>
            </template>
            <template #cell(duration)="data">
              <div class="">{{ data.item.duration }}</div>
            </template>
            <template #cell(action)="data">
              <feather-icon :id="`invoice-row-${data.item.id}-edit-icon`" icon="Edit3Icon" size="16"
                class="cursor-pointer mx-50" @click="editRow(data.item, 1)" />
              <!--
              <feather-icon
                :id="`invoice-row-${data.item.id}-sendmessage-icon`"
                icon="MessageSquareIcon"
                size="16"
                class="cursor-pointer mx-50"
                @click="edit(data.item)"
              />
              <feather-icon
                :id="`invoice-row-${data.item.id}-trash-icon`"
                icon="XIcon"
                size="16"
                class="cursor-pointer mx-50"
                @click="cancelJobConfirm(data.item)"
              />
              -->
            </template>
            <template v-slot:head()="{ field }">
              {{ $t(field.label) }}
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-card>
    <b-modal id="modalEditRow" ref="refEditRow" cancel-variant="outline-secondary" cancel-title="Close" centered
      :title="$t('Edit settings')" size="l" no-close-on-backdrop>
      <template>
        <b-row>
          <b-col>
            <h5>
              <span>{{ $t("Current Value") }} : {{ clickedRow.currentValue }}</span>
            </h5>
            <b-form-group :label="$t('Simulation Value')">
              <b-form-input id="simulationValueInput" v-model="simulationValue" type="number" autofocus trim
                placeholder="Enter an integer value . . ." />
            </b-form-group>
          </b-col>
          <b-col>
            <h5>
              <span>{{ $t("Simulation Duration") }} </span>
            </h5>
            <b-form-group :label="$t('In Seconds')">
              <b-form-input id="simulationValueInput" v-model="simulationDuration" type="number" autofocus trim
                placeholder="Integer value in seconds . . ." />
            </b-form-group>
          </b-col>
        </b-row>
      </template>
      <template #modal-footer>
        <b-button variant="outline-secondary" @click="saveEdit">
          {{ $t("Ok") }}</b-button>
        <b-button variant="outline-secondary" @click="closeEditRowModal">
          {{ $t("Close") }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import {
  BCard,
  BCardBody,
  BCardTitle,
  BCardFooter,
  BRow,
  BCol,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BButton,
  BTable,
  BDropdown,
  BDropdownItem,
  BInputGroupAppend,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import maintenanceStoreModule from "../maintenanceStoreModule";
import useSimulationValuesData from "./components/composables/useSimulationValuesData.js";
import { ref, computed, watch, onMounted } from "@vue/composition-api";
import store from "@/store";
import moment, { duration } from "moment";
import router from "@/router";
export default {
  components: {
    BDropdown,
    BDropdownItem,
    BPagination,
    BCard,
    BCardTitle,
    BFormGroup,
    vSelect,

    BFormInput,
    BButton,
    BRow,
    BCol,
    BTable,
  },
  setup() {
    const currentPage = ref(1);
    const perPage = ref(10);
    const totalRows = ref(0);
    const filter = ref(null);
    let excelFields = [];
    let maxDuration = 0;
    const PAMIS_MODULE_NAME = "pamis-maintenance";
    const allEditData = ref({});
    const refEditRow = ref(null);
    const clickedRow = ref({});
    const simulationDuration = ref(0);
    const simulationValue = ref(0);
    const dropdownOptions = [
      "Radial Vibration",
      "Axial Vibration",
      "Temperature",
      "Acceleration",
      "Radial Axis Kurtosis",
    ];
    const selectedDropdown = ref("Radial Vibration");
    const { tableData, tableColumns, dropdownMap } =
      useSimulationValuesData(selectedDropdown);

    if (!store.hasModule(PAMIS_MODULE_NAME))
      store.registerModule(PAMIS_MODULE_NAME, maintenanceStoreModule);

    function format_date(value) {
      if (value) {
        const myDate = new Date("0001-01-01T00:00:00Z");
        const dateItem = moment(value);
        if (dateItem > myDate) {
          return moment(value).locale("en").format("HH:mm:ss");
        }
      }
    }
    onMounted(() => {
      totalRows.value = tableData.value != null ? tableData.value.length : 0;
    });
    const onFiltered = (filteredItems) => {
      // Trigger pagination to update the number of buttons/pages due to filtering
      totalRows.value = filteredItems.length;
      currentPage.value = 1;
    };
    const editRow = (data) => {
      clickedRow.value = data;
      refEditRow.value.show();
    };

    const saveEdit = (data) => {
      const tableIndex = clickedRow.value["tableIndex"];
      const selectedDropdownVal = dropdownMap.get(selectedDropdown.value);
      if (maxDuration < simulationDuration.value) {
        maxDuration = Number(simulationDuration.value);
      }
      tableData.value[tableIndex]["simulationValue"] = simulationValue.value;

      tableData.value[tableIndex]["duration"] = simulationDuration.value;

      if (!allEditData.value[selectedDropdownVal]) {
        allEditData.value[selectedDropdownVal] = {};
      }
      allEditData.value[selectedDropdownVal][clickedRow.value.name] = {
        name: clickedRow.value.name,
        type: selectedDropdownVal,
        currentValue: clickedRow.value.currentValue,
        targetValue: clickedRow.value.simulationValue,
        duration: clickedRow.value.duration,
      };

      refEditRow.value.hide();
    };
    const isSimulationRunning = computed(() => {
      return store.getters["pamis-maintenance/getSimulationStatus"];
    });

    const startSimulation = () => {
      store.dispatch("pamis-maintenance/StartSimulation", {
        maxDuration: maxDuration,
        data: allEditData.value,
      });
      router.push(`/PredictiveMaintenance`);
    };

    const closeEditRowModal = () => {
      refEditRow.value.hide();
    };
    return {
      dropdownOptions,
      selectedDropdown,
      clickedRow,
      editRow,
      closeEditRowModal,
      onFiltered,
      dropdownMap,
      perPage,
      filter,
      currentPage,
      excelFields,
      refEditRow,
      tableData,
      tableColumns,
      startSimulation,
      format_date,
      simulationDuration,
      simulationValue,
      saveEdit,
      isSimulationRunning,
    };
  },
};
</script>
<style scoped>
.table-card-title {
  margin-bottom: clamp(5px, 2vw, 10px);
}

.click-table:hover {
  cursor: pointer;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

#add-new-job-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>